<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row title-row">
              <div class="col-xl-6 col-lg-6 col-md-6">
                <h4 class="card-title mb-4">Broker List</h4>
              </div>
              <!--col-xl-6 col-lg-6 col-md-6--->

              <div class="col-xl-6 col-lg-6 col-md-6">
                <div class="add-btn-box">
                  <span v-b-modal.modal-center @click="form = {}" class="addbtn"
                    >Add Broker</span
                  >
                </div>
              </div>
              <!--col-xl-6 col-lg-6 col-md-6-->
            </div>

            <simplebar style="max-height: 600px">
              <div class="table-responsive">
                <b-table
                  :items="myProvider"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :busy="loading"
                  ref="table"
                  show-empty
                >
                  <template #empty>
                    <div class="d-flex justify-content-center mb-3">
                      No Record Found
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <!-- <strong>Loading...</strong> -->
                    </div>
                  </template>

                  <template v-slot:cell(id)="data">
                    <div v-if="data.item.id">
                      {{ data.item.id }}
                    </div>
                  </template>

                  <template v-slot:cell(broker_name)="data">
                    <div v-if="data.item.name">
                      {{ data.item.name }}
                    </div>
                  </template>

                  <template v-slot:cell(server)="data">
                    <div v-if="data.item.server">
                      {{ data.item.server }}
                    </div>
                  </template>

                  <template v-slot:cell(currency)="data">
                    <div v-if="data.item.currency">
                      {{ data.item.currency }}
                    </div>
                  </template>

                  <template v-slot:cell(account_number)="data">
                    <div v-if="data.item.account_number">
                      {{ data.item.account_number }}
                    </div>
                  </template>

                  <!-- Status section -->
                  <template v-slot:cell(status)="data">
                    <div class="form-check form-switch form-switch-md mb-3">
                      <input
                        v-model="data.item.status"
                        @change="check(data.item)"
                        class="form-check-input"
                        type="checkbox"
                        id="SwitchCheckSizemd"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(created_at)="data">
                    <div v-if="data.item.created_at">
                      {{ new Date(data.item.created_at).toLocaleDateString() }}
                    </div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <span v-b-modal.modal-center @click="form = data.item"
                      ><i class="far fa-edit text-warning mx-3 action"></i
                    ></span>
                    <span @click="deletebroker(data.item.id)"
                      ><i class="far fa-trash-alt text-danger action"></i
                    ></span>
                  </template>
                </b-table>
              </div>
              <div class="row m-0">
                <div class="col p-0">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </simplebar>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for assigning token to vendor -->
    <b-modal
      id="modal-center"
      size="xl"
      centered
      :title="Object.keys(form).length === 0 ? 'Add Broker' : 'Edit Broker'"
      title-class="font-18"
      hide-footer
      class="padding_modal"
      @hidden="onHidden()"
    >
      <b-form @submit.prevent="onSubmit(form.id)">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Broker Name</label
          >
          <input
            type="text"
            v-model="form.name"
            class="form-control"
            id="exampleFormControlInput1"
          />
          <div class="error" v-if="submitted && !$v.form.name.required">
            Broker name is required
          </div>
          <div class="error" v-if="submitted && !$v.form.name.minLength">
            Broker name minimum character length is 2
          </div>
          <div class="error" v-if="submitted && !$v.form.name.maxLength">
            Broker name maximum character length is 100
          </div>
        </div>

        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Server Name</label
          >
          <input
            type="text"
            v-model="form.server"
            class="form-control"
            id="exampleFormControlInput1"
          />
          <div class="error" v-if="submitted && !$v.form.server.required">
            Server name is required
          </div>
        </div>

        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Select Currency</label
          >
          <multiselect
            v-model="form.currency"
            :options="currencylist"
          ></multiselect>
          <div class="error" v-if="submitted && !$v.form.currency.required">
            Currency name is required
          </div>
        </div>

        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Account Number</label
          >
          <input
            type="text"
            v-model="form.account_number"
            class="form-control"
            placeholder="Account Number"
            id="exampleFormControlInput1"
          />
          <div
            class="error"
            v-if="submitted && !$v.form.account_number.required"
          >
            Account Number is required
          </div>
          <div
            class="error"
            v-if="submitted && !$v.form.account_number.minLength"
          >
            Account Number minimum character length is 9
          </div>
          <div
            class="error"
            v-if="submitted && !$v.form.account_number.maxLength"
          >
            Account Number maximum character length is 20
          </div>
        </div>

        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Confirm Account Number</label
          >
          <input
            type="text"
            v-model="form.confirm_account_number"
            class="form-control"
            placeholder="Confirm Account Number"
            id="exampleFormControlInput1"
          />
          <div
            class="error"
            v-if="submitted && !$v.form.confirm_account_number.sameAsaccountno"
          >
            Account Number should be identical
          </div>
        </div>

        <div class="mt-5 text-end">
          <button type="submit" class="btn btn-outline-info">
            <span v-if="this.formloading">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else> Submit </span>
          </button>
        </div>
      </b-form>
    </b-modal>

    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import currencyList from "../../jsonFiles/currencyList.json";
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

/**
 * Lending component
 */
export default {
  page: {
    title: "Broker List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    simplebar,
    Multiselect,
  },
  data() {
    return {
      title: "Broker List",
      items: [
        {
          text: "Broker List",
          href: "#",
        },
        {
          text: "Broker",
          active: true,
        },
      ],
      loading: false,
      formloading: false,
      // currencylist: ['BNB', 'USDT', 'INR', 'BTC'],
      currencylist: [],
      form: {
        id: "",
        name: "",
        server: "",
        currency: "",
        account_number: "",
        confirm_account_number: "",
      },
      submitted: false,
      totalRows: 0,
      currentRowData: "",
      currentPage: 1,
      perPage: 5,
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "id",
          value: "#",
          sortable: false,
        },
        {
          key: "broker_name",
          value: "broker_name",
          sortable: false,
        },
        {
          key: "server",
          value: "server",
          sortable: false,
        },
        {
          key: "currency",
          value: "currency",
          sortable: false,
        },
        {
          key: "account_number",
          value: "account_number",
          sortable: false,
        },
        {
          key: "status",
          value: "status",
          sortable: false,
        },
        {
          key: "created_at",
          value: "created_at",
          sortable: false,
        },
        {
          key: "action",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      server: { required },
      currency: { required },
      account_number: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(20),
      },
      confirm_account_number: { sameAsaccountno: sameAs("account_number") },
    },
  },
  async created() {
    this.currencylist = currencyList.map((cu) => cu.currency);
  },

  methods: {
    onHidden() {
      this.$refs.table.refresh();
    },
    async check(e) {
      var status = e.status ? 1 : 0;
      var result = await ApiClass.updateFormRequest(
        "admin/broker/update_status/" + e.id,
        true,
        { status: status }
      );
      if (result.data.status_code == "1") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    },
    async onSubmit(id) {
      //event.preventDefault();
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }

      this.formloading = true;
      var call_api = id ? "admin/broker/update/" + id : "admin/broker/create";

      // console.log(call_api);

      var param = {
        account_number: this.form.account_number ?? "",
        name: this.form.name ?? "",
        server: this.form.server ?? "",
        currency: this.form.currency ?? "",
        confirm_account_number: this.form.confirm_account_number ?? "",
      };
      // alert(JSON.stringify(param));
      // console.log(this.form.account_number);

      var result = id
        ? await ApiClass.updateFormRequest(call_api, true, param)
        : await ApiClass.postRequest(call_api, true, param);

      if (result.data.status_code == "1") {
        this.formloading = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
        this.$refs.table.refresh();
        this.$root.$emit("bv::hide::modal", "modal-center");
      } else {
        this.formloading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    },
    deletebroker(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          ApiClass.deleteRequest("admin/broker/delete/" + id, true);
          Swal.fire("Deleted!", "Broker has been deleted.", "success");
          this.$refs.table.refresh();
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Cancelled", "Broker still in our system. :)", "error");
        }
      });
    },
    async myProvider(ctx, callback) {
      this.loading = !this.loading;
      await ApiClass.getRequest(
        "admin/broker/get?per_page=" + ctx.perPage + "&page=" + ctx.currentPage,
        true
      )
        .then((res) => {
          if (res.data.status_code == 1) {
            this.loading = !this.loading;
            const items = res.data.data ? res.data.data.data : [];
            this.currentPage = res.data.data ? res.data.data.current_page : "";
            this.totalRows = res.data.data ? res.data.data.total : "";

            callback(items);
          }
        })
        .bind(this);
    },
    onclose() {
      this.$root.$emit("bv::hide::modal", "modal-center");
    },
  },
};
</script>

<style scoped>
.action {
  font-weight: 600;
  cursor: pointer;
}

.add-btn-box {
  text-align: right;
}

.add-btn-box span.addbtn {
  background-color: #556ee6;
  color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
}
</style>
